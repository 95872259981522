import { relRobotsHandler } from 'theme/meta/utils/robots';
import { RobotsEnum } from 'theme/meta/types';
import config from 'config';

export const manifestMeta = () => {
  return {
    link: [
      {
        rel: 'manifest',
        href: '/assets/manifest.json'
      },
      {
        rel: 'preload',
        as: 'image',
        href: '/assets/images/logo/logo.svg'
      }
    ]
  }
};

export const metaCore = () => {
  const relRobots = relRobotsHandler(RobotsEnum.INDEX, config.xEnv.key);
  return {
    title: 'VARUS',
    htmlAttrs: {
      lang: 'en',
      prefix: 'og:http://ogp.me/ns#'
    },
    meta: {
      ...relRobots,
      description: {
        name: 'description',
        content:
            'Vue Storefront is a standalone PWA storefront for your eCommerce, possible to connect with any eCommerce backend (eg. Magento, Prestashop or Shopware) through the API.'
      },
      mobileWebAppCapable: { name: 'mobile-web-app-capable', content: 'yes' },
      themeColor: { name: 'theme-color', content: '#ffffff' },
      appleWebAppStatusBar: { name: 'apple-mobile-web-app-status-bar-style', content: '#ffffff' },
      ogType: { name: 'og:type', content: 'website' },
      fbVerification: { name: 'facebook-domain-verification', content: 'djx3lofao7f9kdkk198okwf2oo7y2o' }
    },
    link: [
      {
        rel: 'icon',
        type: 'image/png',
        href: '/assets/favicon-150x150.png',
        sizes: '150x150'
      },
      {
        rel: 'icon',
        type: 'image/png',
        href: '/assets/favicon-72x72.png',
        sizes: '72x72'
      },
      {
        rel: 'icon',
        type: 'image/png',
        href: '/assets/favicon-16x16.png',
        sizes: '16x16'
      },
      { rel: 'apple-touch-icon', href: '/assets/favicon-150x150.png' },
      {
        rel: 'apple-touch-startup-image',
        href: '/assets/apple_splash_2048.png',
        sizes: '2048x2732'
      },
      {
        rel: 'apple-touch-startup-image',
        href: '/assets/apple_splash_1668.png',
        sizes: '1668x2224'
      },
      {
        rel: 'apple-touch-startup-image',
        href: '/assets/apple_splash_1536.png',
        sizes: '1536x2048'
      },
      {
        rel: 'apple-touch-startup-image',
        href: '/assets/apple_splash_1125.png',
        sizes: '1125x2436'
      },
      {
        rel: 'apple-touch-startup-image',
        href: '/assets/apple_splash_1242.png',
        sizes: '1242x2208'
      },
      {
        rel: 'apple-touch-startup-image',
        href: '/assets/apple_splash_750.png',
        sizes: '750x1334'
      },
      {
        rel: 'apple-touch-startup-image',
        href: '/assets/apple_splash_640.png',
        sizes: '640x1136'
      },
      { rel: 'manifest', href: '/assets/manifest.json' },
      { rel: 'preconnect', href: 'https://fonts.googleapis.com/', crossorigin: 'anonymous' },
      { rel: 'preconnect', href: 'https://fonts.gstatic.com/', crossorigin: 'anonymous' },
      { rel: 'preconnect', href: 'https://cdn.jsdelivr.net/', crossorigin: 'anonymous' },
      { rel: 'stylesheet', href: '/assets/fonts/fonts.css' },
      {
        rel: 'prefetch',
        href: '/assets/fonts/Inter/Inter-LightBETA.woff2',
        as: 'font',
        type: 'font/woff2',
        crossorigin: 'anonymous'
      },
      {
        rel: 'preload',
        href: '/assets/fonts/Inter/Inter-Regular.woff2',
        as: 'font',
        type: 'font/woff2',
        crossorigin: 'anonymous'
      },
      {
        rel: 'prefetch',
        href: '/assets/fonts/Inter/Inter-Medium.woff2',
        as: 'font',
        type: 'font/woff2',
        crossorigin: 'anonymous'
      },
      {
        rel: 'prefetch',
        href: '/assets/fonts/Inter/Inter-SemiBold.woff2',
        as: 'font',
        type: 'font/woff2',
        crossorigin: 'anonymous'
      },
      {
        rel: 'prefetch',
        href: '/assets/fonts/Inter/Inter-Bold.woff2',
        as: 'font',
        type: 'font/woff2',
        crossorigin: 'anonymous'
      },
      {
        rel: 'preload',
        href: '/assets/fonts/VarusIcons/v-icons.woff?ver=2',
        as: 'font',
        type: 'font/woff',
        crossorigin: 'anonymous'
      },
      {
        rel: 'preload',
        href: '/assets/fonts/StratosLCWeb/StratosLCWeb-Bold.woff2',
        as: 'font',
        type: 'font/woff2',
        crossorigin: 'anonymous'
      }
    ],
    script: [
      {
        src: 'https://cdn.jsdelivr.net/npm/pwacompat@2.0.17/pwacompat.min.js',
        async: true,
        crossorigin: 'anonymous'
      }
    ]
  };
}
